import React, { forwardRef } from "react";
//@ts-ignore
import Navigation from "../../../assets/pages/facility/Navigation.svg";
//@ts-ignore
import BgVideo from "../../../assets/videos/medicana_banner_video_com_3.mp4";

interface PicturebotSectionProps {
  className?: string;
  animate: boolean;
}

const PicturebotSection = forwardRef(({ className, animate }: PicturebotSectionProps, ref: any) => {
  return (
    <section className="relative w-screen h-screen flex items-end justify-center mb-4 md:mb-10" ref={ref}>
      <video
        className="absolute object-cover left-0 h-full top-0 md:top-0 sm:h-full w-screen bg-local overflow-x-hidden z-[0] bg-center bg-cover bg-no-repeat"
        autoPlay
        muted
        loop
        id="myVideo"
        playsInline
      >
        <source src={BgVideo} type="video/mp4" />
      </video>
      <div
        className="absolute pb-20 text-5xl leading-[56px] text-white flex flex-col justify-center items-center gap-6 transition-all ease-out duration-2500 translate-y-10"
        style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      >
        <img src={Navigation} alt="logo" />
        <p className="text-center text-[48px]">Explore Our Facility</p>
      </div>
    </section>
  );
});

export default PicturebotSection;
