import React, { useLayoutEffect, useRef, useState } from "react";
import Page from "../components/Common/Page";
import IndexLayout from "../layouts";
import PicturetopSection from "../components/pages/FacilityPage/PicturetopSection";
import NumberSection from "../components/pages/FacilityPage/NumberSection";
import PicturebotSection from "../components/pages/FacilityPage/PicturebotSection";

const getNewState = (item: string, isTop?: boolean) => {
  return { ...defaultShow, [item]: true, navbar: isTop ? false : true };
};

const defaultShow = {
  itemOne: false,
  itemTwo: false,
  itemThree: false,
  itemFour: false,
  itemFive: false,
  itemSix: false,
  itemSeven: false,
};

function Facility() {
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState<{ [key: string]: boolean }>({
    ...defaultShow,
    navbar: false,
  });

  const [activeStep, setActiveStep] = useState("#banner");

  const refNavbar = useRef(null);
  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);
  const refSix = useRef(null);

  useLayoutEffect(() => {
    const bottomPos = (element: any) =>
      element?.getBoundingClientRect().bottom + window.scrollY;

    const section1Bottom = bottomPos(refOne.current);
    const section2Bottom = bottomPos(refTwo.current);
    const section3Bottom = bottomPos(refThree.current);
    const section4Bottom = bottomPos(refFour.current);
    const section5Bottom = bottomPos(refFive.current);
    const section6Bottom = bottomPos(refSix.current);

    const onScroll = () => {
      if (window) {
        const scrollPos = window.scrollY + window.innerHeight;
        // Inside Nav
        // if (window.scrollY < 16) {
        //   handleSetShow("itemOne", true);
        // }
        // Section1
        if (scrollPos < section1Bottom + 16) {
          if (!show["itemOne"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemOne"]: true,
              navbar: false,
            }));
            setActiveStep("#banner");
          }
          // const percent = Math.round(((scrollPos - window.innerHeight) / (section1Bottom - window.innerHeight)) * 100);
        }
        // Section2
        else if (
          section1Bottom + 16 < scrollPos &&
          scrollPos < section2Bottom
        ) {
          if (!show["itemTwo"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemTwo"]: true,
              navbar: true,
            }));
            setActiveStep("#productlist");
          }
        }
        // Section3
        else if (
          section2Bottom + 250 <= scrollPos &&
          scrollPos < section3Bottom + 250
        ) {
          if (!show["itemThree"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemThree"]: true,
              navbar: true,
            }));
            setActiveStep("#treeinformation");
          }
        }
      }
    };

    const initialProp = () => {
      onScroll();
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll);
    };

    const changeCheck = () => {
      setCheck(!check);
    };

    if (window) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("resize", changeCheck);
      initialProp();
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", changeCheck);
    };
  }, [check]);

  return (
    <IndexLayout ref={refNavbar} animate={show.navbar}>
      <Page>
        <PicturetopSection ref={refOne} animate={show.itemOne} />
        <NumberSection ref={refTwo} animate={show.itemTwo} />
        <PicturebotSection ref={refThree} animate={show.itemThree} />
      </Page>
    </IndexLayout>
  );
}

export default Facility;
