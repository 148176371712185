import React, { forwardRef } from "react";
//@ts-ignore
import BannerImage from "../../../assets/pages/facility/FacilityBanner.png";
import { BannerText } from "../../Common/BannerText";

interface PicturetopSectionProps {
  className?: string;
  animate: boolean;
}

const PicturetopSection = forwardRef(({ className, animate }: PicturetopSectionProps, ref: any) => {
  return (
    <div className="relative w-screen h-screen sm:h-screen bg-center bg-cover bg-no-repeat" ref={ref}>
      <section
        className="w-screen h-screen"
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${BannerImage})`,
        }}
      >
        <BannerText animate={animate} color={'white'}>Our Facility</BannerText>
        <div className="absolute bottom-0 left-0  bg-gradient-to-t from-[#000000e6] opacity-80 w-full h-1/2 z-10" />
      </section>
    </div>
  );
});

export default PicturetopSection;
