import React, { forwardRef } from "react";
//@ts-ignore
import AnimatedNumber from "animated-number-react";

interface NumberSectionProps {
  className?: string;
  animate: boolean;
}

interface ItemProps {
    number:any;
    text:string;
    plus: boolean;
    animate:boolean
}

function Item({ number, text, plus, animate }: ItemProps) {
  const formatValue = (value: any) => Math.floor(value).toLocaleString();
  const formatValueNoColon = (value: any) => Math.floor(value);

  return (
    <div className="flex flex-col items-center col-span-6 md:col-span-4 px-4 md:px-0">
      <div
        className="text-[32px] leading-[40px] text-center transition-all ease-out duration-2500 translate-y-10"
        style={
          animate
            ? { opacity: 1, transform: "translateZ(0px)" }
            : { opacity: 0 }
        }
      >
        <p className="text-medWoodyGreen text-5xl leading-[56px]">
          <AnimatedNumber
            value={number}
            formatValue={number !== 2019 ? formatValue : formatValueNoColon}
            duration={2000}
          />
          {plus && "+"}
        </p>
        <p className="text-medMainGreen text-2xl mt-6">{text}</p>
      </div>
    </div>
  );
}

const PersonalData = [
  {
    number: 80,
    text: "Cultivation Units",
    plus: true,
  },
  {
    number: 2000,
    text: "Plants",
    plus: true,
  },
  {
    number: "500",
    text: "Sqm. of Total Area",
    plus: false,
  },
  {
    number: "11",
    text: "Cultivation Staff",
    plus: false,
  },
  {
    number: "500+",
    text: "Kg. of Premium Biomass",
    plus: true,
  },
  {
    number: "10+",
    text: "Cultivars",
    plus: true,
  },
];

const NumberSection = forwardRef(
  ({ className, animate }: NumberSectionProps, ref: any) => {
    return (
      <section
        className="box-border w-screen h-fit px-[16px] md:px-[100px] lg:px-[140px] py-20"
        ref={ref}
      >
        <div className="grid grid-cols-12 gap-x-0 md:gap-x-10 gap-y-20">
          {PersonalData.map((item) => (
            <Item
              number={item.number}
              text={item.text}
              plus={item.plus}
              animate={animate}
            />
          ))}
        </div>
      </section>
    );
  }
);

export default NumberSection;
